import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemGetRtspPerm';
import RTSPConfigTable from 'components/Web_User_Interface/1440p_Series/Network/RTSP/rtspConfigTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // RTSP",
  "path": "/Web_User_Interface/1440p_Series/Network/RTSP/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The RTSP port is the camera´s RTSP Streaming Port.",
  "image": "./WebUI_1440p_SearchThumb_Network_IP_Configuration.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Network Menu // RTSP' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The RTSP port is the camera´s RTSP Streaming Port.' image='/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Netzwerk/RTSP/' locationFR='/fr/Web_User_Interface/1440p_Series/Network/RTSP/' crumbLabel="RTSP" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/188623ba2401b31f51308dcefded1243/e2e58/1440p_Settings_Network_RTSP.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACNklEQVQoz22RS08TURhA+RdsSaP0MZCYaB9TO49OnwO1nWdbhiIsLCBBXAliDQkRFi5IRI0YFmIiL5WIG7ogSKKJLtix4PccbUtDTFicfN9Nbk5O7u0RBAFhYICg/waRtEv+8SZS/RVSff1aEg/WSU2/Rp/bYFDMkM+m8fl89Pb20tfXR08oFEIQBvDf9CEVxhhb+YbzbBensXctdmOP6tJnxlcPkfQqckJicPAWwUCIUFDoCgX8fj+x8G1cQ+9Q6uAU8zjF3CWd3dIVDF3BLJZRpTSqkkZLZolG4v8LRTGONzrGiFdjZLRGuTqC7bjYThnbrWC75fbZnXmBU39KZbjMUDaLlkyiyCnCd2L0tN+wWxiLUalUsG0b07QwTbODYWAWh7CKQxRMA8WbJVOZZG7CoOalSaoKspQiGhGvCvv7+xFFEdd1sSwLyzI70zSwyh5OfRGrvshUyWE+lWQypzP7sIDnJFBlFU3VEGOXwhatwmg0imEYlEolDKNTZ5lFzHINY3qFe1PLPLE93pgOS26Vt88fMW6lUWSZfDpJLBq7EgaDQQKBQHu/G5dRlRR6LkcmrSElFOZnPNZWGnzY/0LzR5Ofx00utrdZ8AxkWSKjaYTD1whbsyVUZA1J0tAzGRZmH9Dc2WDn0zbn52ecHe3z5/tXTg92eDlpM5xV2vcjkctP6cq6wrgoo8oaY0aO5SmLk40GF6cH/Do+4nDrHVvra5x8fM/v3U1WJwrcdzJIkkrkX+FfcjWIkF/9oVgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/188623ba2401b31f51308dcefded1243/e4706/1440p_Settings_Network_RTSP.avif 230w", "/en/static/188623ba2401b31f51308dcefded1243/d1af7/1440p_Settings_Network_RTSP.avif 460w", "/en/static/188623ba2401b31f51308dcefded1243/7f308/1440p_Settings_Network_RTSP.avif 920w", "/en/static/188623ba2401b31f51308dcefded1243/c986f/1440p_Settings_Network_RTSP.avif 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/188623ba2401b31f51308dcefded1243/a0b58/1440p_Settings_Network_RTSP.webp 230w", "/en/static/188623ba2401b31f51308dcefded1243/bc10c/1440p_Settings_Network_RTSP.webp 460w", "/en/static/188623ba2401b31f51308dcefded1243/966d8/1440p_Settings_Network_RTSP.webp 920w", "/en/static/188623ba2401b31f51308dcefded1243/b8544/1440p_Settings_Network_RTSP.webp 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/188623ba2401b31f51308dcefded1243/81c8e/1440p_Settings_Network_RTSP.png 230w", "/en/static/188623ba2401b31f51308dcefded1243/08a84/1440p_Settings_Network_RTSP.png 460w", "/en/static/188623ba2401b31f51308dcefded1243/c0255/1440p_Settings_Network_RTSP.png 920w", "/en/static/188623ba2401b31f51308dcefded1243/e2e58/1440p_Settings_Network_RTSP.png 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/188623ba2401b31f51308dcefded1243/c0255/1440p_Settings_Network_RTSP.png",
              "alt": "Web User Interface - 1440p Series - Network RTSP Configuration",
              "title": "Web User Interface - 1440p Series - Network RTSP Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For the RTSP Port please refer to `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`RTSP Streaming`}</a>{` for details on the use of the camera´s RTSP video stream. Remember to forward the RTSP Port if you want to access the RTSP Stream over the internet with your DDNS Address.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <RTSPConfigTable mdxType="RTSPConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      